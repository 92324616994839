


























import Vue from "vue";
import { deleteUser } from "@/api/UsersAPI";

export default Vue.extend({
  name: "DeleteUserDialog",

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
    loading: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },
    close: function () {
      this.isOpen = false;
    },
    deleteUserConfirm(): void {
      this.loading = true;
      deleteUser(this.$props.userId)
        .then(() => {
          this.close();
          this.$router.replace("/users");
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
