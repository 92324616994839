





















































































import DetailCard from "./DetailCard.vue";
import Vue, { PropType } from "vue";
import { IViewDetailsUser } from "@iot-uta-devices/iot-uta-devices-interfaces";
import {
  hasMachineAdminPermission,
  hasMachineProgrammingPermission,
  hasUserAdminPermission,
} from "@/utils/UserPermissions";
import { getAllMachines, getMachines } from "@/api/MachinesAPI";
import { patchAllMachinesToUser, deleteAllUserMachines } from "@/api/UsersAPI";
import AddMachineDialog from "@/components/AddMachineDialog.vue";
import { EndButtonType } from "@/types/Client";
import DeleteUserMachineDialog from "@/components/DeleteUserMachineDialog.vue";

type Machine = {
  readonly id: string;
  readonly name: string;
};

export default Vue.extend({
  props: {
    user: {
      type: Object as PropType<IViewDetailsUser>,
      required: true,
    },
  },
  data: () => ({
    endButtons: [] as Array<EndButtonType>,
    dataTableHeight: 200,
    machines: [] as Array<Machine>,
    toDeleteID: "",
    dialogDeleteOpen: false,
    dialogAddOpen: false,
    loading: true,
    error: "",
    headers: [
      { text: "Macchina", align: "start", value: "name" },
      { text: "Actions", align: "end", value: "actions" },
    ],
  }),
  name: "UserMachinesCard",
  components: { DeleteUserMachineDialog, AddMachineDialog, DetailCard },

  watch: {
    user: function () {
      this.initializeData();
    },
  },

  mounted: function () {
    this.initializeData();
  },

  methods: {
    canEditMachines(): boolean {
      return (
        (hasMachineAdminPermission(this.$store.getters.userRoles) ||
          hasMachineProgrammingPermission(this.$store.getters.userRoles)) &&
        hasUserAdminPermission(this.$store.getters.userRoles)
      );
    },
    deleteItem(machine: Machine): void {
      this.toDeleteID = machine.id;
      // @ts-expect-error wrong type
      this.$refs.deleteUserMachineDialogRef.activateDialog();
    },

    openAddDialog(): void {
      // @ts-expect-error wrong type
      this.$refs.addDialogRef.activateDialog();
    },

    initializeData() {
      this.loading = true;
      this.error = "";
      if (this.user.machines === "allMachines") {
        getAllMachines()
          .then((response) => {
            this.machines = response.map((machine) => ({
              id: machine.machine.id,
              name: machine.machine.device_code,
            }));
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          });
        this.endButtons = [
          {
            text: "Elimina tutte",
            icon: "fas fa-minus-square",
            action: () => {
              deleteAllUserMachines(this.user.username).then(() => {
                this.$emit("reload_user");
              });
            },
            disabled: !this.canEditMachines(),
          },
        ];
      } else {
        getMachines(this.user.machines)
          .then((response) => {
            this.machines = response.map((machine) => ({
              id: machine.machine.id,
              name: machine.machine.device_code,
            }));
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          });
        this.endButtons = [
          {
            text: "Aggiungi",
            icon: "fas fa-plus",
            action: () => {
              this.openAddDialog();
            },
            disabled: !this.canEditMachines(),
          },
          {
            text: "Aggiungi tutte",
            icon: "fas fa-plus-square",
            action: () => {
              patchAllMachinesToUser(this.user.username).then(() => {
                this.$emit("reload_user");
              });
            },
            disabled: !this.canEditMachines(),
          },
        ];
      }
    },
  },
});
