










































import DetailCard from "./DetailCard.vue";
import Vue, { PropType } from "vue";
import { IViewDetailsUser } from "@iot-uta-devices/iot-uta-devices-interfaces";
import { putUserRoles } from "@/api/UsersAPI";
import {
  hasMachineAdminPermission,
  hasMachineProgrammingPermission,
  hasUserAdminPermission,
} from "@/utils/UserPermissions";

export default Vue.extend({
  props: {
    user: {
      type: Object as PropType<IViewDetailsUser>,
      required: true,
    },
  },
  data: () => ({
    roles: {
      machines_viewer: false,
      machines_programmer: false,
      machines_admin: false,
      users_viewer: false,
      users_admin: false,
    },
    machineRoleNames: {
      machines_viewer: "Visualizzazione macchine",
      machines_programmer: "Programmazione macchine",
      machines_admin: "Amministrazione macchine",
    },
    userRoleNames: {
      users_viewer: "Visualizzazione utenti",
      users_admin: "Amministrazione utenti",
    },
  }),
  name: "UserRolesCard",
  components: { DetailCard },
  mounted: function () {
    this.roles = {
      machines_viewer: this.user.roles.machines_viewer,
      machines_programmer: this.user.roles.machines_programmer,
      machines_admin: this.user.roles.machines_admin,
      users_viewer: this.user.roles.users_viewer,
      users_admin: this.user.roles.users_admin,
    };
  },
  methods: {
    async submit(): Promise<void> {
      if (this.roles.machines_admin || this.roles.machines_programmer) {
        this.roles.machines_programmer = true;
        this.roles.machines_viewer = true;
      }
      if (this.roles.users_admin) {
        this.roles.users_viewer = true;
      }
      putUserRoles(this.user.username, this.roles).then(() => {
        this.$emit("reload_user");
      });
    },
    canEditUser(): boolean {
      return hasUserAdminPermission(this.$store.getters.userRoles);
    },
    isCheckboxDisabled(key: string): boolean {
      return (
        (key === "machines_viewer" &&
          (hasMachineAdminPermission(this.roles) ||
            hasMachineProgrammingPermission(this.roles))) ||
        (key === "machines_programmer" &&
          hasMachineAdminPermission(this.roles)) ||
        (key === "users_viewer" && hasUserAdminPermission(this.roles))
      );
    },
  },
});
