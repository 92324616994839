


























import Vue from "vue";
import { deleteUserMachine } from "@/api/UsersAPI";

export default Vue.extend({
  name: "DeleteUserMachineDialog",

  props: {
    username: {
      type: String,
      required: true,
    },
    machineId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
    loading: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },
    close: function () {
      this.isOpen = false;
      this.$emit("close");
    },
    deleteMachineConfirm(): void {
      this.loading = true;
      deleteUserMachine(this.$props.username, this.$props.machineId)
        .then(() => {
          this.close();
          this.$emit("reload_user");
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
