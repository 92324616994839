





























































































































































import DetailCard from "./DetailCard.vue";
import Vue, { PropType } from "vue";
import { IViewDetailsUser } from "@iot-uta-devices/iot-uta-devices-interfaces";
import { putUserDetails, putUserPassword } from "@/api/UsersAPI";
import { hasUserAdminPermission } from "@/utils/UserPermissions";
import { extend, setInteractionMode } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import UneditableTextField from "@/globalComponents/UneditableTextField.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto.",
});

extend("email", {
  ...email,
  message: "Indirizzo email non valido.",
});


export default Vue.extend({
  props: {
    user: {
      type: Object as PropType<IViewDetailsUser>,
      required: true,
    },
  },
  data: () => ({
    password: "",
    status: true,
    description: "",
    email: "",
    receives_machine_error_emails: false,
    receives_machine_status_change_emails: false,
    receives_machine_scheduler_emails: false
  }),
  name: "UserDetails",
  components: { UneditableTextField, DetailCard },
  mounted: function () {
    this.password = "";
    this.status = this.user.status;
    this.description = this.user.description as string;
    this.email = this.user.email as string;
    this.receives_machine_error_emails = this.user.receives_machine_error_emails as boolean;
    this.receives_machine_status_change_emails = this.user.receives_machine_status_change_emails as boolean;
    this.receives_machine_scheduler_emails = this.user.receives_machine_scheduler_emails as boolean;
  },
  methods: {
    regeneratePassword(): void {
      this.password = Math.random().toString(36).slice(-8);
    },
    async submitDetails(): Promise<void> {
      // @ts-expect-error wrong type
      const valid = await this.$refs.observer.validate();
      if (
        valid &&
        (this.description !== this.user.description ||
          this.status !== this.user.status ||
          this.email !== this.user.email ||
          this.receives_machine_error_emails !== this.user.receives_machine_error_emails ||
          this.receives_machine_status_change_emails !== this.user.receives_machine_status_change_emails ||
          this.receives_machine_scheduler_emails !== this.user.receives_machine_scheduler_emails)
      ) {
        const payload = {
          description: this.description,
          status: this.status,
          email: this.email,
          receives_machine_error_emails: this.receives_machine_error_emails,
          receives_machine_status_change_emails: this.receives_machine_status_change_emails,
          receives_machine_scheduler_emails: this.receives_machine_scheduler_emails
        };
        
        putUserDetails(this.user.username, payload).then(() => {
          this.$emit("reload_user");
        });
      }
    },
    async submitPassword(): Promise<void> {
      putUserPassword(this.user.username, this.password).then(() => {
        this.password = "";
        this.$emit("reload_user");
      });
    },
    canEditUser(): boolean {
      return hasUserAdminPermission(this.$store.getters.userRoles);
    },
  },
});
