var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('detail-card',{staticClass:"fill-height",attrs:{"title":"Lista macchine","icon":"fas fa-cog","end-buttons":this.endButtons}},[(!_vm.loading && !_vm.error)?_c('div',[(_vm.user.machines !== 'allMachines')?_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.machines,"headers":_vm.headers,"hide-default-header":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":!_vm.canEditMachines(),"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" fas fa-trash-alt ")])]}},{key:"no-data",fn:function(){return [_vm._v(" Nessuna Macchina da visualizzare. ")]},proxy:true}],null,false,2833138615)}):_c('div',{staticClass:"mx-8 mt-4"},[_vm._v(" L'utente vede tutte le macchine. "),_c('br'),_vm._v(" Premi "),_c('v-icon',[_vm._v("fas fa-minus-square")]),_vm._v(" per togliere la visualizzazione di tutte le macchine all'utente. ")],1),_c('delete-user-machine-dialog',{ref:"deleteUserMachineDialogRef",attrs:{"machine-id":this.toDeleteID,"username":_vm.user.username},on:{"reload_user":function () {
          this$1.$emit('reload_user');
        },"close":function () {
          this$1.toDeleteID = '';
        }}}),_c('add-machine-dialog',{ref:"addDialogRef",attrs:{"username":_vm.user.username,"user-machines-ids":_vm.user.machines === 'allMachines' ? [] : _vm.user.machines},on:{"reload_user":function () {
          this$1.$emit('reload_user');
        }}})],1):_vm._e(),(_vm.loading)?_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","disable-pagination":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('tbody',_vm._l((3),function(index){return _c('tr',{key:index},[_c('td',[_c('v-skeleton-loader',{attrs:{"loading":true,"type":"text"}})],1)])}),0)]},proxy:true}],null,false,1258403415)}):_vm._e(),_c('div',{staticClass:"ma-8"},[(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(this.error)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }