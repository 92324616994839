
































































import {
  hasMachineViewPermission,
  hasUserAdminPermission,
} from "@/utils/UserPermissions";
import ComponentsCardContainer from "@/components/ComponentsCardContainer.vue";
import UserDetailsCard from "@/components/UserDetailsCard.vue";
import Vue from "vue";
import { getUser } from "@/api/UsersAPI";
import {
  IRole,
  IViewDetailsUser,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
import UserRolesCard from "@/components/UserRolesCard.vue";
import UserMachinesCard from "@/components/UserMachinesCard.vue";
import ActionButton from "@/globalComponents/ActionButton.vue";
import DeleteUserDialog from "@/components/DeleteUserDialog.vue";

export default Vue.extend({
  name: "UserDetails",
  data: () => ({
    user: null as unknown as IViewDetailsUser,
    userRoles: null as unknown as IRole,
    userMachines: null,
    loading: true,
    dialogDeleteOpen: false,
    error: "",
  }),
  components: {
    DeleteUserDialog,
    ActionButton,
    UserMachinesCard,
    UserRolesCard,
    ComponentsCardContainer,
    UserDetailsCard,
  },

  mounted: function () {
    this.loading = true;
    this.fetchUser();
  },

  methods: {
    canViewMachines(): boolean {
      return hasMachineViewPermission(this.$store.getters.userRoles);
    },
    canEditUser(): boolean {
      return hasUserAdminPermission(this.$store.getters.userRoles);
    },
    fetchUser(): void {
      this.error = "";
      getUser(this.$route.params.userID)
        .then((response: IViewDetailsUser) => {
          this.user = response;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDeleteUserDialog(): void {
      // @ts-expect-error wrong type
      this.$refs.deleteUserDialog.activateDialog();
    },
  },
});
