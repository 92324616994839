






















import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "UneditableTextField",

  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    endIcon: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String as PropType<"orange" | "red">,
      required: false,
      default: "",
    },
  },

  methods: {
    blurComponent: function (event: FocusEvent) {
      const target = event?.target as HTMLFormElement;
      target?.blur();
    },
  },

  computed: {
    getClass: function (): string {
      if (this.color) {
        return this.color === "orange"
          ? "orangeColoredInput"
          : "redColoredInput";
      } else {
        return "";
      }
    },
  },
});
