




























































import Vue, { PropType } from "vue";
import { getAllMachinesExceptTheUserOnes } from "@/api/MachinesAPI";
import { putMachineToUser } from "@/api/UsersAPI";

type Machine = {
  readonly id: string;
  readonly name: string;
  readonly client_name: string | null;
  readonly client_address: string | null;
};

export default Vue.extend({
  name: "AddMachineDialog",

  props: {
    username: {
      type: String,
      required: true,
    },
    userMachinesIds: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
    machines: [] as Array<Machine>,
    search: "",
    error: "",
    loading: true,
    headers: [
      { text: "Codice SGD", align: "start", value: "name" },
      { text: "Nome cliente", align: "start", value: "client_name" },
      { text: "Indirizzo cliente", align: "start", value: "client_address" },
    ],
  }),

  methods: {
    activateDialog: function () {
      this.initializeData();
      this.isOpen = true;
    },
    close: function () {
      this.isOpen = false;
    },
    initializeData: function () {
      this.loading = true;
      this.error = "";
      getAllMachinesExceptTheUserOnes(this.userMachinesIds as Array<string>)
        .then((response) => {
          this.machines = response.map((machine) => ({
            id: machine.machine.id,
            name: machine.machine.device_code,
            client_name: machine.machine.client_name,
            client_address: machine.machine.client_address,
          }));
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addMachine: function (machine: Machine) {
      putMachineToUser(this.username, machine.id)
        .then(() => {
          this.$emit("reload_user");
          this.close();
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
