
































import Vue, { PropType } from "vue";
import { hasMachineAdminPermission } from "@/utils/UserPermissions";
import { EndButtonType } from "@/types/Client";

export default Vue.extend({
  name: "DetailCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    endButtons: {
      type: Array as PropType<Array<EndButtonType>>,
      required: false,
      default: () => [],
    },
    refresh: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    actions(): Array<EndButtonType> {
      return [
        ...this.endButtons,
        ...(this.refresh
          ? [
              {
                text: "Ricarica",
                icon: "mdi-refresh",
                action: this.updateView,
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    updateView() {
      // @ts-expect-error ts(2339)
      this.$bus.$emit("update-detail-card");
      // @ts-expect-error ts(2339)
      this.$bus.$emit("update-detail-card-content");
    },
    canEditMachines(): boolean {
      return hasMachineAdminPermission(this.$store.getters.userRoles);
    },
  },
});
